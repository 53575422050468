/* Sign Up page */
.signup > div {
  height: 100vh;
}

.signup {
  background: #f1f1f1;
}

.companSideBar {
  text-align: center;
  width: 100% !important;
  min-height: 107vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background-color: var(--grayColor);
  color: var(--blueColor);
  padding-top: 1em;
}
.companSideBar h1 {
  font-size: 6rem !important;
  margin-bottom: 1rem;
  color: var(--blueColor);
}
.companSideBar p {
  font-size: 1.2rem;
  text-align: start;
  margin-bottom: 3rem;
}
.signup input {
  border-radius: 5px;
  font-size: 1.25rem !important;
  background-color: #f1f1f1 !important;
  color: var(--blueColor) !important;
}

.signup input::placeholder {
  color: var(--blueColor) !important;
  font-size: 0.78em;
}
.formInput {
  border-radius: 5px;
  height: 59px;
  font-size: 1.25rem;
  background-color: #f1f1f1 !important;
  color: var(--blueColor) !important;
  border: 1px solid var(--infoColor) !important;
}
.formInput::placeholder {
  color: var(--blueColor) !important;
}
.formInput:focus {
  outline: transparent !important;
}
.loginBtn,
.merchantBtn {
  border-radius: 5px;
  width: 100%;
  height: 59px;
  margin-top: 1rem;
  font-size: 1.25rem;
  background-color: var(--blueColor);
  color: #fff;
  border: none;
}
.merchantBtn:hover,
.loginBtn:hover,
.signupBtn:hover,
.connect:hover,
.passwordBtn:hover {
  background-color: var(--blueColor);
  color: #fff;
  border: none;
}
.merchantBtn:focus,
.loginBtn:focus,
.signupBtn:focus,
.connect:focus,
.passwordBtn:hover {
  background-color: var(--blueColor);
  color: #fff;
  border: none;
}
.signupForm {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  background-color: white;
  border-radius: 15px;
  padding: 3em 7em;
  position: relative;
  width: 750px;
  min-height: 650px;
  padding-top: 9em;
}

.signupForm h3,
.signupForm button span {
  font-family: Righteous;
}
.signupForm input,
.signupForm button,
.signupForm p,
.signupForm span {
  font-family: Lexend;
}

.toggleContainer {
  /* position: absolute;
  top: 8em;
  left: 50%;
  transform: translateX(-50%); */
}

.toggleBtn {
  background-color: rgb(177, 122, 80) !important;
}

.signupForm h3 {
  text-align: center;
  font-size: 1.6rem;
  color: var(--blueColor);
  font-weight: bold;
  position: absolute;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.cards h3 {
  color: var(--blueColor);
  font-size: 1.2rem;
  margin: 1.5rem 0;
}
.signupStore {
  position: relative;
  padding-top: 0.5rem;
  max-width: 320px;
  margin: 2rem auto;
  margin-bottom: 5rem;
}
.line,
.darkLine {
  width: 230px;
  margin: auto;
  margin: 1rem;
  background-color: var(--grayColor);
  height: 4px;
}
.darkLine {
  background-color: var(--blueColor);
}

.userBtn {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.activeBtn,
.connectBtn {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.connectBtn,
.activeBtn {
  left: unset;
  right: 0;
}
.userBtn span,
.activeBtn span,
.connectBtn span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin: auto;
  border-radius: 50%;
  background-color: var(--blueColor);
}
.userBtn a,
.activeBtn a,
.connectBtn a {
  display: inline-block;
  margin-top: 1rem;
  text-decoration: none;
  color: #57586e;
  font-size: 1.3rem;
}
.userBtn a,
.activeBtn a {
  color: var(--blueColor);
}
.connectBtn span {
  background-color: var(--grayColor);
}
.formContent {
  max-width: 670px;
  margin: 2em auto auto;
  text-align: center;
}

.signupBtn,
.passwordBtn {
  text-decoration: none;
  border-radius: 5px;
  width: 136px;
  height: 59px;
  margin-top: 1rem;
  margin-left: 79%;
  font-size: 1.25rem;
  background-color: rgb(177, 122, 80);
  color: #fff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.passwordBtn {
  width: 180px;
  margin-left: unset;
  margin: auto;
}
.account {
  max-width: 585px;
  text-align: center;
  margin: 0 auto;
}
.cards {
  margin-top: 7.3 rem;
}

.account p {
  font-size: 1rem;
  color: var(--blueColor);
  margin: 2rem 0;
}
.hereBtn {
  font-size: 1rem;
  color: var(--blueColor);
  text-decoration: none;
}
.accountItem {
  background-color: var(--grayColor);
  height: 75px;
  border-radius: 15px;
  margin: 1rem 0;
  padding: 0.5rem;
}
.connect {
  border-radius: 5px;
  width: 100%;
  height: 59px;
  font-size: 1.25rem;
  background-color: var(--blueColor);
  color: #fff;
  border: none;
}

.row {
  align-items: center;
}
.merchant {
  text-align: right;
  padding-right: 25%;
}
.merchantBtn {
  width: 335px;
  margin: 2rem auto;
}

.form {
  width: 100%;
  max-width: 325px;
  text-align: center;
}
/* affiliate Styling */
.affSidebar p {
  font-size: 2.125rem;
}
.affSignupForm {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.affSignupForm h3 {
  margin: 2rem 0;
}

.messageContainer {
  display: flex;
  height: 100vh;
  align-items: center;
  width: 30vw;
  text-align: center;
  margin: auto;
}

@media screen and (max-width: 992px) {
  .signupBtn {
    margin-left: 50%;
  }
}
@media screen and (max-width: 768px) {
  .signupBtn {
    margin-left: 0;
  }

  .signupForm h3 {
    position: relative;
    transform: none;
    left: unset;
    top: unset;
  }

  .toggleContainer {
    position: relative;
    transform: none;
    left: unset;
    top: unset;
  }
}
