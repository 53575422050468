/* Login page */

.login {
  background: #f1f1f1;
}
.login > div {
  min-height: 100vh;
}
.companSideBar {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grayColor);
  color: var(--blueColor);
}
.companSideBar h1 {
  font-size: 5rem !important;
  margin-bottom: 1rem;
  color: var(--blueColor);
}
.companSideBar p {
  font-size: 1.25rem;
  text-align: start;
  margin-bottom: 3rem;
}
.companSideBar img {
  max-width: 100%;
  height: auto;
}
.loginForm {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  background-color: white;
  border-radius: 15px;
  padding: 9em;
  position: relative;
  width: 750px;
  height: 650px;
}

.form input {
  background-color: #f1f1f1;
  font-size: 1.25rem;
  color: var(--blueColor);
}
.form input::placeholder {
  color: var(--blueColor);
  font-size: 0.78em;
}
.formInput {
  border-radius: 5px;
  height: 59px;
  font-size: 1.25rem;
  background-color: #f1f1f1 !important;
  color: var(--blueColor);
  border: 1px solid var(--infoColor);
}
.form Input::placeholder {
  color: var(--blueColor);
}
.formInput:focus {
  outline: transparent !important;
}
.loginBtn {
  border-radius: 5px;
  width: 100%;
  height: 59px;
  margin-top: 1rem;
  font-size: 1.25rem;
  background-color: rgb(177, 122, 80);
  color: #fff;
  border: none;
}
.loginBtn:hover,
.passwordBtn:hover {
  background-color: rgb(177, 122, 80);
  color: #fff;
  border: none;
}
.form {
  width: 100%;
  max-width: 325px;
  text-align: center;
}
.contact {
  border: 1px solid var(--blueColor);
  text-align: left;
  width: 100%;
  max-width: 900px;
  padding: 2rem;
  border-radius: 10px;
  margin-top: 1rem;
  color: var(--blueColor);
  margin: auto;
}
.contact p {
  font-size: 2rem;
}
.contact input {
  border: 1px solid var(--blueColor) !important;
  background-color: #fff !important;
}
.contact input::placeholder,
.textArea::placeholder {
  color: var(--blueColor) !important;
}
.contact strong {
  font-size: 2.5rem;
  font-weight: 700;
}
.contactDesc {
  margin: 0 0 0 3rem;
}
.contactDesc div {
  margin-bottom: 1rem;
}

.loginForm h3 {
  text-align: center;
  font-size: 1.6rem;
  color: var(--blueColor);
  font-weight: bold;
  position: absolute;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.loginForm h3,
.loginForm button span {
  font-family: Righteous;
}
.loginForm input,
.loginForm button,
.loginForm p,
.loginForm span {
  font-family: Lexend;
}

.signupBtn {
  text-decoration: none;
  color: var(--blueColor);
  /* font-size: 1.15rem; */
  text-align: center;
  font-family: Lexend;
}
.signupBtn:hover {
  color: var(--blueColor);
}
.resetBtn,
.saveBtn,
.passwordBtn {
  text-decoration: none;
  border-radius: 5px;
  width: 100%;
  height: 59px;
  font-size: 1.25rem;
  background-color: var(--blueColor);
  color: #fff;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.saveBtn {
  width: 140px;
  margin-right: 100%;
}
.resetBtn:hover,
.saveBtn:hover {
  background-color: var(--blueColor);
  color: #fff;
}

.passwordBtn {
  width: 180px;
  margin-left: unset;
  margin: auto;
}
.textArea {
  border: 1px solid var(--blueColor) !important;
  border-radius: 5px;
  background-color: #fff !important;
  font-size: 1.25rem;
  color: var(--blueColor);
}

/* affiliate Styling */
.affSidebar p {
  font-size: 2.125rem;
}
.affSignupForm {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.affSignupForm h3 {
  margin: 2rem 0;
}

.badgeContainer {
  display: flex;
  align-items: center;
  /* margin: 0 3em; */
  margin-bottom: 3em;
}

.badgeContainer > div:last-child {
  margin: 0;
  margin-left: 1.3em;
  width: 280px;
}

.badgeContainer > div:last-child p,
.badgeContainer > div:last-child h3 {
  font-size: 1.2em;
  text-align: left;
  margin-bottom: 0;
  font-family: Lexend;
}

.companCard h2,
.companCard p {
  font-family: Lexend;
}

.badgeContainer > div:last-child h3 {
  font-weight: bold;
  font-size: 1.3em !important;
  font-family: Righteous;
}

.badgeContainer > div:first-child {
  /* background: url(/icons/elipseBackground.svg); */
  background-repeat: no-repeat;
  min-width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  column-count: 2;
  display: block !important;

  max-width: 310px;
  margin: auto;
  column-gap: 20px; /* Adjust the gap between columns */
}

.logoContainer img {
  margin-right: 1.1em;
}

.companCard h4 {
  font-size: 1.2em !important;
  margin-top: 3em;
}

.topSection {
  margin-bottom: 2em;
}

@media only screen and (max-width: 992px) {
  .companSideBar p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .companCard {
    min-height: 100vh;
  }

  .companCard h4 {
    margin-top: 1em;
  }

  .logoContainer img {
    width: 150px;
    height: 80px;
  }

  .login > div {
    min-height: 100%;
  }
  .companSideBar {
    padding: 1rem;
  }
  .form {
    width: 100%;
    max-width: 310px;
    margin: 2rem auto;
  }
  .companSideBar img {
    width: 100%;
  }
}
